export const BPM_MINIMUM = 40;
export const BPM_MAXIMUM = 200;
export const LAYER_MAXIMUM = 5;

export const MICROPHONE_STATE = {
  PREAPPROVED: 'PREAPPROVED',
  INIT: 'INIT',
  REQUESTED_PERMISSION: 'REQUESTED_PERMISSION',
  DISABLED: 'DISABLED',
  ENABLED: 'ENABLED'
};

export const RECORDING_STATE = {
  OFF: 'OFF',
  RECORDING: 'RECORDING',
  CAPTURING: 'CAPTURING'
};

export const NOTE_VALUE_OFF = 'NOTE_VALUE_OFF';
export const NOTE_VALUE_ON = 'NOTE_VALUE_ON';
export const NOTE_VALUE_ACCENT = 'NOTE_VALUE_ACCENT';
